* {
  font-family: 'como', sans-serif;
  box-sizing: border-box;
  text-align: left;
}

video {
  object-fit: cover!important;
  margin-bottom: 0px!important;
}

iframe {
  width: 100%;
  height: 100%;
}

input {
  width: 100%;
  background: rgba(54,54,54,1)!important;
  border: 1px solid rgba(255,255,255,.2);
  height: 40px;
  padding-left: 10px;
  border-radius: 0px;
  outline: none;
  color: white;
  box-sizing: border-box;
}

input:focus {
  border: 1px solid #4f7361;
}

.fake-input {
  width: 100%;
  background: rgba(54,54,54,1)!important;
  border: 1px solid rgba(255,255,255,.2);
  height: 40px;
  padding-left: 10px;
  border-radius: 0px;
  outline: none;
  color: white;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  background: rgba(54,54,54,1)!important;
  border: 1px solid rgba(255,255,255,.2);
  padding-left: 10px;
  border-radius: 0px;
  outline: none;
  color: white;
  box-sizing: border-box;
}

textarea:focus {
  border: 1px solid #4f7361;
}

.jb-ae {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.jc-ac {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jb-ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jb-as {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ja-ac {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.je-ac {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.js-ac {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.js-as {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.jb-as {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.jc-as {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-col {
  flex-direction: column;
}

.outsyde-inner-content {
  background: #1f1e20;
}

.App {
  color: white;
  height: 100%
}

.header {
  color: white;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  background: black;
  z-index: 100000;
}

.header-floater {
  height: 62px;
}

@media (min-width: 992px) {
  .header {
    height: 113px;
  }
  .header-floater {
    height: 113px;
  }
}

h1 {
  font-weight: 700!important;
  font-size: 38px;
  text-shadow: 2px 2px 3px rgba(0,0,0,.3);
  line-height: 1;
  margin-bottom: 0px
}

@media (max-width: 992px) {
  h1 {
    font-size: 30px
  }
}

.header-link {
  font-size: 18px;
  font-weight: 700!important;
  cursor: pointer;
  text-shadow: 2px 2px 3px rgba(0,0,0,.3);
  text-transform: uppercase;
  color: white;
  text-decoration: none
}

.header-link:hover {
  color: #4f7361;
}

.footer {
  width: 100%;
  color: white
}

.map-top-cover {
  background: pink;
  width: 100%;
  height: 40vh;
  clip-path: polygon(100% 0, 0 0, 100% 24%);
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000
}

.map-bottom-cover {
  background: pink;
  width: 100%;
  height: 40vh;
  clip-path: polygon(0 76%, 0 100%, 100% 100%);
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1000
}

.btn1 {
  height: 40px;
  background: #4f7361;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  font-family: como, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  border: 1.5px solid rgba(0,0,0,0);
}

.btn1:hover {
  border: 1.5px solid #4f7361;
  background: #19181a;
  color: #4f7361;
  text-shadow: 1px 1px 1px black
}

.btn2 {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #4f7361;
  text-transform: uppercase;
  font-family: como, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  border: 1.5px solid #4f7361;
}

.btn2:hover {
  background: rgba(71, 151, 97, .2);
  text-shadow: 1px 1px 1px black
}

.truncate1 {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1;
   -webkit-box-orient: vertical;
}

.truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}

.truncate3 {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;
}

.truncate4 {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
           line-clamp: 4;
   -webkit-box-orient: vertical;
}

.grey-hover:hover {
  background: #1f1e20;
}

.green-text-hover:hover {
  color: #4f7361;
}

.la-footer-icon {
  color: white
}

.mapboxgl-ctrl-top-right {
  top: 50%;
  transform: translate(0px, -50%);
}

.mapboxgl-ctrl-group {
  padding-left: 6px !important;
  padding-right: 6px !important;
  box-shadow: none !important;
  background: rgba(0,0,0,.8);
}

.mapboxgl-ctrl-group button {
  width: 34px !important;
  height: 44px !important;
  color: white !important;
  background: transparent;
  border-radius: 0px !important;
}

.mapboxgl-ctrl-group button+button {
  border-top: 1px solid rgba(255,255,255,.35);
}

/* .mapboxgl-ctrl-zoom-in {
  height: 38px !important;
  padding-bottom: 4px
}

.mapboxgl-ctrl-zoom-out {
  height: 42px !important;
}

.mapboxgl-ctrl-compass {
  height: 38px !important;
  padding-top: 4px
} */

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27 viewBox=%270 0 29 29%27%3E%3Cpath d=%27M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z%27/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27 viewBox=%270 0 29 29%27%3E%3Cpath d=%27M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z%27/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23479761%27 viewBox=%270 0 29 29%27%3E%3Cpath d=%27M10.5 14l4-8 4 8h-8z%27/%3E%3Cpath id=%27south%27 d=%27M10.5 16l4 8 4-8h-8z%27 fill=%27%23fff%27/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox=%270 0 20 20%27 xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27%3E%3Cpath d=%27M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z%27/%3E%3Ccircle id=%27dot%27 cx=%2710%27 cy=%2710%27 r=%272%27/%3E%3Cpath id=%27stroke%27 d=%27M14 5l1 1-9 9-1-1 9-9z%27 display=%27none%27/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27 viewBox=%270 0 29 29%27%3E%3Cpath d=%27M24 16v5.5c0 1.75-.75 2.5-2.5 2.5H16v-1l3-1.5-4-5.5 1-1 5.5 4 1.5-3h1zM6 16l1.5 3 5.5-4 1 1-4 5.5 3 1.5v1H7.5C5.75 24 5 23.25 5 21.5V16h1zm7-11v1l-3 1.5 4 5.5-1 1-5.5-4L6 13H5V7.5C5 5.75 5.75 5 7.5 5H13zm11 2.5c0-1.75-.75-2.5-2.5-2.5H16v1l3 1.5-4 5.5 1 1 5.5-4 1.5 3h1V7.5z%27/%3E%3C/svg%3E") !important
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23fff%27 viewBox=%270 0 29 29%27%3E%3Cpath d=%27M18.5 16c-1.75 0-2.5.75-2.5 2.5V24h1l1.5-3 5.5 4 1-1-4-5.5 3-1.5v-1h-5.5zM13 18.5c0-1.75-.75-2.5-2.5-2.5H5v1l3 1.5L4 24l1 1 5.5-4 1.5 3h1v-5.5zm3-8c0 1.75.75 2.5 2.5 2.5H24v-1l-3-1.5L25 5l-1-1-5.5 4L17 5h-1v5.5zM10.5 13c1.75 0 2.5-.75 2.5-2.5V5h-1l-1.5 3L5 4 4 5l4 5.5L5 12v1h5.5z%27/%3E%3C/svg%3E");
}

.feed-nav-icon {
  color: rgba(255,255,255,.12);
  font-size: 32px;
}

.feed-nav-icon:hover {
  color: rgba(255,255,255,.5);
}

.footer-link {
  color: white;
  text-decoration: none;
}

.outsyde-static-map {
  max-width: 100%
}

.outsyde-text-btn {
  color: #4f7361;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ldfh {
  z-index: -100;
  visibility: hidden;
  background: black;
  height: 113px;
  width: 100%;
  left: 0px;
  top: 0px;
  position: fixed;
  opacity: 0;
  transition: all 120ms linear 100ms;
}

.learn-btn {
  color: white;
  text-decoration: none
}

.learn-btn:hover {
  color: #4f7361;
}

.learn-btn:hover .ldfh {
  z-index: -1;
  opacity: 1;
  visibility: visible;
  transition: all 120ms linear 350ms;
}

.learn-drop {
  z-index: -100;
  visibility: hidden;
  background: black;
  font-size: 16px;
  color: white;
  left: 0px;
  top: 113px;
  width: 100%;
  position: fixed;
  opacity: 0;
  transition: all 120ms linear 100ms;
}

.learn-btn:hover .learn-drop {
  z-index: 1000;
  opacity: 1;
  visibility: visible;
  transition: all 120ms linear 350ms;
}

.video-repeat-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  line-height: 1;
  transform: translate(-50%, -50%);
  z-index: 100000
}

.post-action-btn {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1.5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  /* box-shadow: 1px 1px 2px rgba(0,0,0,.2); */
  filter: drop-shadow(1px 1px 2px rgba(0,0,0,.6));
}

.post-type-icon {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  border: 1.5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 2px rgba(0,0,0,.2);
  /* filter: drop-shadow(1px 1px 2px rgba(0,0,0,.6)); */
}

/* embed style changes */
.c4 {
  padding: 0px!important
}

.doc-content {
  font-family: como, sans-serif!important
}
